body {
  box-sizing: border-box;

  background-image: url("./Images/3.jpg");
  height: 100%; 

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

/* div {
} */

.content {
  color: floralwhite;
  font-family: "Raleway", sans-serif;
  position: absolute;
  max-height: 80vh;
  overflow-y: scroll;
  margin-right: 1vw;
  /* scroll-snap-type: y mandatory; */
  -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.content::-webkit-scrollbar {
    display: none;
}

a {
  color: floralwhite;
}

a:link{
  color: floralwhite;
  font-size: 2.8vh;
  text-decoration-line: underline;
}

a:hover{
  color:darkslategray;
}

a:visited{
  color:floralwhite;
}

h {
  font-size: 3vh;
}

p {
  font-size: 2.8vh;
}

li {
  font-size: 2.8vh;
}

.current {
  border-bottom: 4px solid white;
} 

.navCol {
  margin-right: 0 !important;
  padding-right: 0 !important;
}